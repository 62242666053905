import { template as template_c36d4e1d021c46909af53fd6bbbfb989 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c36d4e1d021c46909af53fd6bbbfb989(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
