import { template as template_83184d13f1bc4310983bfb0311258beb } from "@ember/template-compiler";
const FKLabel = template_83184d13f1bc4310983bfb0311258beb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
